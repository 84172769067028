import { useEffect, useState } from 'react';
import './App.css';
import ReactLoading from 'react-loading';
import axios from 'axios';

//toast
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import subscribeGif from './click4.gif';



function App() {

  const [loading, setLoading] = useState(true);
  const [showSubscribe, setShowSubscribe] = useState(false);

  //params
  const [msisdn, setMsisdn] = useState("");
  const [sourceIp, setSourceIp] = useState("");
  const [productId, setProductId] = useState("");
  const [txId, setTxId] = useState("");

  useEffect(() => {
    setLoading(true);
    toast.dismiss();

    const urlParams = new URLSearchParams(window.location.search);
    const prodid = urlParams.get('productId');
    const transactionId = urlParams.get('txId');


    if (prodid) {
      setProductId(prodid);
    }

    if(transactionId){
      setTxId(transactionId);
    }


    if (productId != "") {
      let hashedMsisdn = "";

      //SUBSCRIBE 

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://api.wap.ent.libroafrica.com/api/request/token/get-hash-token`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic M3ZkaDZnMGJiZ2syY3Njaml2Z2h0ZDcwbjY6MXRhMW1xaHRkNW9tNW1vMTM1bmcwbXA1cHY3bmxxOHZpZTE4djY4YjJvMDJldnR1MTdzZg=='
        }
      };

      axios.request(config)
        .then((response) => {
          if (response.data.status == "000") {
            //he token generated successfully
            let heToken = response.data.token;

            setSourceIp(response.data.ip);

            //get hashed msisdn
            let configFetchMasked = {
              method: 'get',
              maxBodyLength: Infinity,
              url: 'https://identity.safaricom.com/partner/api/v2/fetchMaskedMsisdn',
              headers: {
                'X-App': 'he-partner',
                'X-MessageID': '1234',
                'X-Source-System': 'he-partner',
                'Authorization': 'Bearer ' + heToken
              }
            };

            axios.request(configFetchMasked)
              .then((response) => {
                const responseData = response.data; // Extracting the data from the response
                hashedMsisdn = responseData.ServiceResponse.ResponseBody.Response.Msisdn;

                console.log(hashedMsisdn);
                if (hashedMsisdn.length > 0) {

                  setMsisdn(hashedMsisdn);
                  setLoading(false);
                  setShowSubscribe(true);

                } else {
                  toast.error('🦄 Please activate your safaricom mobile data!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                  });

                  setLoading(false);
                }
              })
              .catch((error) => {
                toast.error('🦄 Something went wrong! Please try again in a few minutes or contact Support!', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
                });

                setLoading(false);

              });
          }
        })
        .catch((error) => {
          toast.error('🦄 Something went wrong! Please try again in a few minutes or contact Support!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });

          setLoading(false);


        });

    }else{

        toast.error('🦄 Something went wrong! Invalid request!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
  
        setLoading(false);      


    }
  }, [productId ])



  function generateConsent(msisdn, source_ip) {
    //get hashed msisdn
    setLoading(true);
    setShowSubscribe(false);

    let config = {
      method: 'get',
      url: `https://api.wap.ent.libroafrica.com/api/request/wap-subscribe?hashed_msisdn=${msisdn}&ip=${source_ip}&agent=${navigator.userAgent}&productId=${productId}&txId=${txId}`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    };

    axios.request(config)
      .then((response) => {
        if (response.data.status == "000") {
          window.location.href = response.data.consent_url;
        }
      })
      .catch((error) => {
        toast.error('🦄 Something went wrong! Please try again in a few minutes or contact Support!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });


      });
  }


  return (
    <div className='app-container'>

      {loading && <ReactLoading type={"cylon"} color={"white"} height={150} width={150} />}

      {showSubscribe &&
        <button id="btn-subcribe" onClick={() => generateConsent(msisdn, sourceIp)} style={{"background":"none","border":"none"}}>
          <img src={subscribeGif} alt="subscribe" className='btngif' />
        </button>
      }


      <ToastContainer />

    </div>
  );
}

export default App;
